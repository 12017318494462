import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useNavigate } from 'react-router-dom';
import { useCreateOrganization } from '../api/create-organization';
import { TermsOfAgreement } from '@/components/terms-of-agreement';
import { orgNoSchema } from '@/features/misc/data/orgno-schema';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Spinner } from '@/components/ui/spinner';
import storage from '@/utils/storage';
import { NewOrganizationTerms } from '../components/new-organization-terms';
import { Separator } from '@/components/ui/separator';
import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { useMe } from '@/features/auth/api/get-me';
import { useOrganizerStore } from '@/stores/organizerStore';
import { Asterisk } from '@/components/ui/asterisk';

const formSchema = z.object({
  organizerName: z
    .string()
    .max(255, {
      message: 'organizer_name_max_length'
    })
    .refine(value => !!value, {
      message: 'organizer_name_required'
    }),
  signerName: z
    .string()
    .max(500, {
      message: 'signer_name_max_length'
    })
    .refine(value => !!value, {
      message: 'signer_name_required'
    }),
  signerEmail: z.string().email({
    message: 'signer_email_invalid'
  }),
  signerTelephone: z
    .string()
    .refine(value => !!value, {
      message: 'signer_telephone_required'
    })
    .refine(value => !value || validatePhoneNumber(value), {
      message: 'signer_telephone_invalid'
    }),
  acceptedUserTerms: z.boolean().refine(value => value === true, {
    message: 'accept_user_terms_validation_text'
  }),
  acceptedPersonalDataTerms: z.boolean().refine(value => value === true, {
    message: 'accept_person_data_terms_validation_text'
  }),
  orgNo: orgNoSchema
});

export function CreateOrganization() {
  const { mutateAsync: createOrganization, isLoading } = useCreateOrganization({});

  const setOrganizationId = useOrganizerStore(state => state.setOrganizationId);
  const navigate = useNavigate();

  const { data: user } = useMe({});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      signerEmail: user?.username || ''
    },
    values: {
      signerEmail: user?.username || '',
      signerName: user?.name || '',
      signerTelephone: user?.telephone || '',
      orgNo: '',
      acceptedUserTerms: false,
      acceptedPersonalDataTerms: false,
      organizerName: ''
    }
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      const newOrganization = await createOrganization({ ...values, signerEmail: user?.username } as any);
      setOrganizationId(newOrganization.organizerId);
      navigate(`/organizations/edit/${newOrganization.organizerId}`);
    } catch (error) {}
  }

  const hasAcceptedNewOrganizationTerms = storage.hasAcceptedOrganizationTerms();

  if (!hasAcceptedNewOrganizationTerms) {
    return <NewOrganizationTerms />;
  }

  return (
    <div className="space-y-6 flex flex-col w-full h-full">
      <div className="space-y-2 overflow-y-auto flex-grow">
        <Card>
          <CardHeader>
            <CardTitle>
              <Label name="create_organization_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
            </CardTitle>
            <CardDescription>
              <Label name="create_organization_description" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
                <div className="grid md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="organizerName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> <Asterisk className="ml-2" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} maxLength={255} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="orgNo"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="org_no" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> <Asterisk className="ml-2" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <Separator className="my-2" />
                <div className="grid md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="signerName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="signer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> <Asterisk className="ml-2" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} maxLength={500} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="signerEmail"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="signer_email" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> <Asterisk className="ml-2" />
                        </FormLabel>
                        <FormControl>
                          <Input readOnly disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="signerTelephone"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="signer:telephone" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> <Asterisk className="ml-2" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} maxLength={32} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="acceptedUserTerms"
                  render={({ field }) => (
                    <FormItem className="lg:w-1/2 lg:pr-2 w-full">
                      <FormLabel>
                        <Label name="user_terms_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> <Asterisk className="ml-2" />
                      </FormLabel>
                      <FormControl>
                        <TermsOfAgreement
                          accepted={field.value}
                          onAccept={() => field.onChange(true)}
                          LabelComponent={<Label name="user_terms_label" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />}
                          BodyComponent={
                            <div className="[&>button]:max-w-full text-sm break-words">
                              <Label name="user_terms_body" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                            </div>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="acceptedPersonalDataTerms"
                  render={({ field }) => (
                    <FormItem className="lg:w-1/2 lg:pr-2  w-full">
                      <FormLabel>
                        <Label name="personal_data_terms_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> <Asterisk className="ml-2" />
                      </FormLabel>
                      <FormControl>
                        <TermsOfAgreement
                          accepted={field.value}
                          onAccept={() => field.onChange(true)}
                          LabelComponent={<Label name="personal_data_terms_label" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />}
                          BodyComponent={
                            <div className="[&>button]:max-w-full text-sm break-words">
                              <Label name="personal_data_terms_body" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                            </div>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button disabled={isLoading || form.formState.isSubmitting || !form.formState.isDirty} type="submit">
                  {isLoading && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
                  {isLoading ? <Label name="creating" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} /> : <Label name="create" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />}
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
