import { useForm } from 'react-hook-form';
import { useUpdateUser } from '../api/update-user';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '../api/get-user';
import { z } from 'zod';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { updateUserSchema } from '../data/update-user-schema';
import { UserType } from '../types';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { roles } from '../data/data';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { passwordSchema } from '@/features/auth/data/password-schema';
import { updatePassword } from '../api/update-password';
import { PasswordInput } from '@/components/password-input';
import { Checkbox } from '@/components/ui/checkbox';

const changePasswordSchema = z
  .object({
    newPassword: passwordSchema,
    confirmNewPassword: passwordSchema
  })
  .refine(data => data.newPassword === data.confirmNewPassword, {
    message: 'password_does_not_match',
    path: ['confirmNewPassword']
  });

export function User() {
  const { id } = useParams();

  const { data: user } = useUser({
    userId: Number(id),
    config: {
      enabled: !!id
    }
  });

  const { mutateAsync: updateUser, isLoading: isUpdating } = useUpdateUser({
    userId: 1
  });

  const updateUserForm = useForm<z.infer<typeof updateUserSchema>>({
    resolver: zodResolver(updateUserSchema),
    values: {
      userId: user?.userId ?? 0,
      name: user?.name ?? '',
      username: user?.username ?? '',
      telephone: user?.telephone ?? '',
      role: user?.role ?? UserType.ADMIN,
      locked: user?.locked ?? false
    }
  });

  const changePasswordForm = useForm<z.infer<typeof changePasswordSchema>>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmNewPassword: ''
    }
  });

  const handleUpdateUserSubmit = async (values: z.infer<typeof updateUserSchema>) => {
    await updateUser(values);
  };

  async function handleChangePasswordSubmit(values: z.infer<typeof changePasswordSchema>) {
    try {
      if (!user) return;
      await updatePassword({ ...values, userId: user?.userId });
      changePasswordForm.reset();
    } catch (error) {}
  }

  return (
    <div className="w-full max-w-md space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>
            <Label name="user_details_card_title" groupName={LabelGroup.ADMIN_USER_EDIT} />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...updateUserForm}>
            <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={updateUserForm.handleSubmit(handleUpdateUserSubmit)} className="space-y-8">
              <FormField
                control={updateUserForm.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="username" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={updateUserForm.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="name" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={updateUserForm.control}
                name="telephone"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="telephone" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="telephone" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={updateUserForm.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Label name="user_role" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <Select onValueChange={value => field.onChange(Number(value))} value={field.value.toString()}>
                      <FormControl>
                        <SelectTrigger className="[&>span]:text-left ">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {roles.map(({ value }) => (
                          <SelectItem key={value} value={value.toString()}>
                            <Label name={'user_role_' + value} groupName={LabelGroup.ADMIN_USER_EDIT} />
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormField
                  control={updateUserForm.control}
                  name="locked"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                      <FormControl>
                        <Checkbox checked={!!field.value} onCheckedChange={checked => field.onChange(checked)} />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>
                          <Label name="locked" groupName={LabelGroup.ADMIN_USER_EDIT} />
                        </FormLabel>
                        <FormDescription>
                          <Label name="locked_description" groupName={LabelGroup.ADMIN_USER_EDIT} />
                        </FormDescription>
                      </div>
                    </FormItem>
                  )}
                />
                <FormMessage />
              </FormItem>
              <Button disabled={isUpdating || updateUserForm.formState.isSubmitting || !updateUserForm.formState.isDirty} type="submit">
                <Label name={isUpdating ? 'updating' : 'update'} groupName={LabelGroup.ADMIN_USER_EDIT} />
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            <Label name="change_password" groupName={LabelGroup.PROFILE} />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...changePasswordForm}>
            <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={changePasswordForm.handleSubmit(handleChangePasswordSubmit)} className="space-y-8">
              <FormField
                control={changePasswordForm.control}
                name="newPassword"
                render={({ field }) => (
                  <FormItem className="max-w-sm">
                    <FormLabel>
                      <Label name="new_password" groupName={LabelGroup.PROFILE} />
                    </FormLabel>
                    <FormControl>
                      <PasswordInput {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={changePasswordForm.control}
                name="confirmNewPassword"
                render={({ field }) => (
                  <FormItem className="max-w-sm">
                    <FormLabel>
                      <Label name="confirm_new_password" groupName={LabelGroup.PROFILE} />
                    </FormLabel>
                    <FormControl>
                      <PasswordInput {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button disabled={changePasswordForm.formState.isSubmitting || !changePasswordForm.formState.isDirty} type="submit">
                <Label name={isUpdating ? 'updating' : 'update'} groupName={LabelGroup.ADMIN_USER_EDIT} />
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}
