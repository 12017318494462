import { useRef, useState } from 'react';
import { useEventPersons } from '../api/get-event-persons';
import { Input } from '@/components/ui/input';
import { EventPerson } from '../types';
import useOnClickOutside from '@/hooks/use-cllick-outside';
import { ListNavigator } from '@/components/list-navigator';
import { cn } from '@/utils';

interface EventPersonSuggestionInputProps {
  person: any;
  handleUpdateEventPerson: any;
  handleSelectSuggestion: any;
  propName: keyof EventPerson;
}

export function EventPersonSuggestionInput({ person, handleUpdateEventPerson, handleSelectSuggestion, propName }: EventPersonSuggestionInputProps) {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef(null);
  const suggestionListRef = useRef(null);
  const navigatorRef = useRef(null);

  const { data: suggestions = [] } = useEventPersons({ config: { enabled: true } });

  const suggestionsFiltered = suggestions.filter(suggestion => {
    const propMatches = suggestion[propName]?.toString().toLowerCase().includes(person[propName]?.toString().toLowerCase());

    const allPropertiesMatch = suggestion.firstName === person.firstName && suggestion.lastName === person.lastName && suggestion.organizationName === person.organizationName && suggestion.title === person.title && suggestion.party === person.party;

    return propMatches && !allPropertiesMatch;
  });

  useOnClickOutside(
    [{ current: inputRef.current }, { current: suggestionListRef.current }, { current: navigatorRef.current }],
    () => {
      setShowSuggestions(false);
      setIsFocused(false);
    },
    showSuggestions
  );

  return (
    <div className="relative">
      <Input
        ref={inputRef}
        value={person[propName] ?? ''}
        onKeyDown={e => {
          if (e.key === 'Tab') {
            setShowSuggestions(false);
            setIsFocused(false);
          }
        }}
        onFocus={() => setIsFocused(true)}
        onChange={e => {
          handleUpdateEventPerson(e.target.value);
          setShowSuggestions(true);
        }}
        className="w-full"
      />

      <div ref={suggestionListRef} className={cn('absolute bg-card z-10 left-0 top-full border border-input w-full shadow-sm rounded-md max-h-96 overflow-y-auto', suggestionsFiltered.length > 0 && person[propName] && showSuggestions && isFocused ? 'block' : 'hidden')}>
        <ListNavigator containerRef={navigatorRef} inputRef={inputRef}>
          {suggestionsFiltered.map(suggestion => (
            <li
              key={suggestion.eventPersonId}
              className="cursor-pointer text-sm mx-1 py-2 px-2  hover:bg-accent   data-[state='on']:bg-muted data-[state='on']:outline-zinc-100"
              onClick={e => {
                e.stopPropagation();
                handleSelectSuggestion(suggestion);
                setShowSuggestions(false);
                setIsFocused(false);
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  handleSelectSuggestion(suggestion);
                  setShowSuggestions(false);
                }
              }}>
              <div className="flex items-center space-x-2">
                {suggestion.firstName} {suggestion.lastName} {suggestion.title} {suggestion.organizationName} {suggestion.party}
              </div>
            </li>
          ))}
        </ListNavigator>
      </div>
    </div>
  );
}
