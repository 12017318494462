import { OrganizationUser } from '@/features/organizer/types';

type User = {
  userId: number;
  username: string;
  name: string;
  locked: boolean;
  role: UserType;
  userType: UserType;
  userGuid: string;
  emailVerified: boolean;
  telephone: string;
  lastLogin: string;
  inserted: string;
  organizerUsers?: OrganizationUser[];
  failedLoginAttempts: number;
};

enum UserType {
  ADMIN = 10,
  MANAGER = 20,
  ORGANIZER = 30,
}

export { UserType };
export type { User };
