import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { EventStatusBadge } from '@/features/event/components/event-status-badge';
import { EventStatus, EventTime, LogCategory, TEvent } from '@/features/event/types';
import { Copy, Files } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { ExclamationCircle } from 'react-bootstrap-icons';
// import { useEvents } from '@/features/event/api/getEvents';
// import { useOrganizerStore } from '@/stores/organizerStore';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { formatDate } from '@/utils/formatDate';
import { ConfirmButton } from '@/components/confirm-button';
import { cn } from '@/utils';
import { formateTime } from '@/utils/formatTime';
import { label } from '@/utils/label';

interface OrganizerEventListItemProps {
  event: TEvent & {
    draft?: TEvent | null | undefined;
  };
  onCopy?: ((eventId: number) => void) | null;
}

export function OrganizerEventListItem({ event, onCopy }: OrganizerEventListItemProps) {
  const creator = event?.logs?.find(log => log.category === LogCategory.CREATED || log.category === LogCategory.EVENT_COPY)?.user?.username;
  const createdTime = event?.logs?.find(log => log.category === LogCategory.CREATED || log.category === LogCategory.EVENT_COPY)?.inserted;

  const comments = [event?.descriptionComment, event?.generalComment, event?.socialIssueComment, event?.titleComment]?.filter(Boolean);

  return (
    <Card>
      <CardHeader className="space-y-2 pb-2">
        <div className="flex justify-between">
          <div className=" flex gap-2 flex-col ">
            <EventStatusBadge status={event.status} className="w-fit" />
            {event?.draftParentId && (
              <div className="bg-brand/10 text-orange-900 text-xs lg:text-sm font-semibold flex items-center w-fit p-2 gap-2 px-2 rounded-md">
                <Files className="h-4 w-4  stroke-brand" />
                {label('event_draft_version', LabelGroup.ORGANIZER_EVENT_LIST)}
              </div>
            )}
          </div>
          <div className="sm:flex gap-x-2 items-center  hidden">
            {onCopy && (
              <ConfirmButton
                onConfirm={() => onCopy(event.eventId)}
                TitleComponent={<Label name="copy_event_title" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />}
                DescriptionComponent={<Label name="copy_event_description" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />}
                ButtonComponent={
                  <Button type="button" size="sm" variant="outline" className="text-primary">
                    <Copy className="w-4 h-4 mr-2" />
                    <Label name="copy_event_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
                  </Button>
                }
              />
            )}
            <Link to={`/events/edit/${event.eventId}`}>
              <Button variant="outline" size="sm">
                {event.status !== EventStatus.Pending && !event?.draft ? <Label name="edit_event_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} /> : <Label name="view_event_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />}
              </Button>
            </Link>
          </div>
        </div>
        <CardTitle className="flex items-start gap-x-2 text-sm text-foreground">
          <span className="lg:text-lg text-sm w-fit">{event.draftParentId ?? event.eventId}</span>

          <span className={cn('break-words text-wrap  lg:text-lg  max-w-[calc(100%_-_30px)] text-sm ', event?.status === EventStatus.Cancelled && 'line-through')}>{event.title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <p className="font-semibold text-sm text-muted-foreground">{event.category}</p>
        <div className="flex flex-col space-y-1 w-fit rounded-md text-sm">
          {event?.times
          ?.sort((a, b) => +new Date(a.eventStart) - +new Date(b.eventStart))
          ?.map(
            (time: EventTime) =>
              time.eventStart &&
              time.eventEnd && (
                <span className="" key={time.eventTimeId}>
                  {formatDate(time.eventStart)} - {formateTime(formatDate(time.eventEnd))}
                </span>
              )
          )}
        </div>
        {creator && (
          <div className="text-sm flex gap-2 items-start md:items-center md:flex-row flex-col ">
            <Label name="created_by" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            <div className="flex items-center gap-1">
              <Avatar className="w-6 h-6">
                <AvatarImage />
                <AvatarFallback className="bg-brand text-white uppercase">{creator?.substring(0, 1)}</AvatarFallback>
              </Avatar>
              {creator}
            </div>
            {formatDate(createdTime ?? '')}
          </div>
        )}
        {comments?.length > 0 && (event.status === EventStatus.ActionRequired || event.status === EventStatus.Rejected || event?.status === EventStatus.Approved) && (
          <div className="flex items-center  space-x-2 w-full">
            <ExclamationCircle className="w-4 h-4 text-amber-500 " />
            <Link className="underline text-sm" to={`/events/edit/${event.eventId}`}>
              <Label name="new_comments" groupName={LabelGroup.ORGANIZER_EVENT_LIST} /> ({comments.length})
            </Link>
          </div>
        )}
        <div className="flex gap-x-2 items-center  sm:hidden">
          {onCopy && (
            <ConfirmButton
              onConfirm={() => onCopy(event.eventId)}
              TitleComponent={<Label name="copy_event_title" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />}
              DescriptionComponent={<Label name="copy_event_description" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />}
              ButtonComponent={
                <Button type="button" size="sm" variant="outline" className="text-primary">
                  <Copy className="w-4 h-4 mr-2" />
                  <Label name="copy_event_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
                </Button>
              }
            />
          )}
          <Link to={`/events/edit/${event.eventId}`}>
            <Button variant="outline" size="sm">
              {event.status !== EventStatus.Pending && !event?.draft ? <Label name="edit_event_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} /> : <Label name="view_event_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />}
            </Button>
          </Link>
        </div>
      </CardContent>
      {event?.draft && (
        <CardFooter>
          <div className="[&>div]:border-dashed [&>div]:border-2 [&>div]:border-brand w-full ">
            <OrganizerEventListItem event={{ ...event.draft, draft: null }} onCopy={null} />
          </div>
        </CardFooter>
      )}
    </Card>
  );
}
