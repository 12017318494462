export function formatDate(dateString: string, showTime = true): string {
  // Ensure dateString is in ISO 8601 format and is marked as UTC

  if (!dateString.includes('T')) {
    return dateString;
  }

  const isoDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;

  // Convert to local date and time
  const localDate = new Date(isoDateString);

  // Format the date using the options
  const formattedDate = localDate.toLocaleDateString('sv-SE', {
    timeZone: 'Europe/Stockholm',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: showTime ? '2-digit' : undefined,
    minute: showTime ? '2-digit' : undefined
  });

  return formattedDate;
}
