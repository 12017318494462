import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useUpdateEvent } from '../api/updateEvent';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEvent } from '../api/getEvent';
import { EventCategory, EventStatus } from '../types';
import { StatusStepper } from '../components/status-stepper';
import { SendSection } from '../components/(sections)/send-section';
import { useEventStore } from '@/stores/eventStore';
import { parseEventLogToComments } from '@/utils/parseEventLogToComments';
import { EditEventSkeleton } from '../components/edit-event-placeholder';
import { ArrowLeft, Files, Info, Lock, UnlockIcon } from 'lucide-react';
import { useSendEvent } from '../api/send-event';
import { sections } from '../data/sections';
import { Sidebar } from '../components/sidebar';
import { useEffect, useRef, useState } from 'react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { cn } from '@/utils';
import { PreviewSection } from '../components/(sections)/preview-section';
import { label } from '@/utils/label';
import { useToast } from '@/components/ui/use-toast';
import { ReturnDue } from '../components/(alert)/return-due';
import DeviationSettings from '../components/deviation-settings';
import { NavigationTabs } from '../components/navigation-tabs';
import { Separator } from '@/components/ui/separator';
import { useOrganizerStore } from '@/stores/organizerStore';
import { useEvents } from '../api/getEvents';
import { useStartSession } from '../api/start-session';
import { useEndSession } from '../api/end-session';
import { useMe } from '@/features/auth/api/get-me';
import { ActiveEditorAlertDialog } from '@/features/admin/components/active-editor-alert-dialog';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { CancelledEventAlert } from '../components/(alert)/cancelled-event-alert';
// import { YearDueAlert } from '../components/(alert)/year-due-alert';
import { baseEventSchema } from '../data/base-event-schema';
import { sendEventSchema } from '../data/send-event-schema';
import { Comment } from '../components/comment';
import { useConfirm } from '@/providers/confirm';
import { queryClient } from '@/lib/react-query';
import { useTimePeriodRestrictions } from '@/hooks/use-time-period-restrictions';
import { Spinner } from '@/components/ui/spinner';
import { useYear } from '@/features/settings/api/get-year';

export function EditEvent() {
  const [isLocked, setIsLocked] = useState<boolean>(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { id } = useParams();

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const organizerId = useOrganizerStore(state => state.organizationId);

  const setIsAssigned = useEventStore(state => state.setIsAssigned);
  const setEventComments = useEventStore(state => state.setComments);

  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useForm<z.infer<typeof baseEventSchema>>({
    resolver: zodResolver(baseEventSchema),
    // mode: 'onChange',
    defaultValues: {
      sendToReview: false
    }
  });

  const { data: user } = useMe({});

  const { mutateAsync: startSession, isError: hasActiveEditor } = useStartSession({
    eventId: Number(id),
    config: {
      onSuccess: () => {
        setIsLocked(false);
        queryClient.invalidateQueries(['event', Number(id)]);
        queryClient.refetchQueries(['event', Number(id)]);
      }
    }
  });
  const { mutateAsync: endSession } = useEndSession({ eventId: Number(id) });

  const { mutateAsync: updateEvent, isLoading: isUpdating } = useUpdateEvent({
    organizerId: Number(organizerId),
    eventId: Number(id)
  });

  const { mutateAsync: sendEvent, isLoading: isSending } = useSendEvent({
    eventId: Number(id)
  });

  const { data: organization } = useOrganization({
    organizerId: Number(organizerId),
    config: { enabled: !!organizerId }
  });

  const { data: events = [], isLoading: isLoadingEvents } = useEvents({
    organizerId: Number(organizerId),
    config: {
      enabled: !!organizerId
    }
  });

  const { data: activeYear } = useYear({});

  const {
    data: event,
    isLoading: isFetching,
    isFetched
  } = useEvent({
    eventId: Number(id),
    config: {
      enabled: !!id && id !== undefined,
      onSuccess: data => {
        const titleComment = data?.titleComment;
        const descriptionComment = data?.descriptionComment;
        const socialIssueComment = data?.socialIssueComment;
        const generalComment = data?.generalComment;

        const logs = parseEventLogToComments(data?.logs ?? [])?.reduce((acc: any, comment) => {
          if (comment.logText.field === 'title' && titleComment) {
            return [...acc, comment];
          }
          if (comment.logText.field === 'social' && socialIssueComment) {
            return [...acc, comment];
          }
          if (comment.logText.field === 'description' && descriptionComment) {
            return [...acc, comment];
          }
          if (comment.logText.field === 'general' && generalComment) {
            return [...acc, comment];
          }
          return acc;
        }, []);

        console.log(logs);

        setIsAssigned(false);
        setEventComments(logs ?? []);

        form.reset({
          status: data?.status ?? 0,
          yearId: data?.yearId ?? 0,
          times:
            data?.times?.length === 0
              ? [
                  {
                    eventTimeId: 0,
                    eventId: 0,
                    eventStart: '',
                    eventEnd: '',
                    inserted: ''
                  }
                ]
              : data?.times ?? [],
          title: data?.title ?? '',
          description: data?.description ?? '',
          category: data?.category ?? '',
          eventType: data?.eventType ?? '',
          languages: typeof data?.languages === 'string' ? data?.languages?.split(';') : data?.languages ?? [],
          organizationType: data?.organizationType ?? '',
          topic: data?.topic ?? '',
          topic2: data?.topic2 ?? '',
          socialIssue: data?.socialIssue ?? '',
          eventId: data?.eventId ?? null,
          locationId: data?.locationId ?? 0,
          description_lastVersion: data?.description_lastVersion ?? '',
          title_lastVersion: data?.title_lastVersion ?? '',
          socialIssue_lastVersion: data?.socialIssue_lastVersion ?? '',
          locationDescription: data?.locationDescription ?? '',
          contactPerson1Email: data?.contactPerson1Email ?? '',
          contactPerson1Name: data?.contactPerson1Name ?? '',
          contactPerson1Telephone: data?.contactPerson1Telephone ?? '',
          contactPerson1Title: data?.contactPerson1Title ?? '',
          contactPerson2Email: data?.contactPerson2Email ?? '',
          contactPerson2Name: data?.contactPerson2Name ?? '',
          contactPerson2Telephone: data?.contactPerson2Telephone ?? '',
          contactPerson1Organization: data?.contactPerson1Organization ?? '',
          contactPerson2Organization: data?.contactPerson2Organization ?? '',
          showEmail: data?.showEmail ?? false,
          showTelephone: data?.showTelephone ?? false,
          url1: data?.url1 ?? '',
          url2: data?.url2 ?? '',
          url3: data?.url3 ?? '',
          facebookUrl: data?.facebookUrl ?? '',
          xurl: data?.xurl ?? '',
          linkedInUrl: data?.linkedInUrl ?? '',
          persons: data?.persons ?? [],
          organizers: data?.organizers ?? [],
          digitalStreamingType: data?.digitalStreamingType ?? '',
          digitalStreamingUrl: data?.digitalStreamingUrl ?? '',
          digitalMeetingUrl: data?.digitalMeetingUrl ?? '',
          digitalMeetingDescription: data?.digitalMeetingDescription ?? '',
          digitalArchiveUrl: data?.digitalArchiveUrl ?? '',
          accessibilityText: data?.accessibilityText ?? false,
          accessibilityTeleloop: data?.accessibilityTeleloop ?? false,
          accessibilityVisualInterpretation: data?.accessibilityVisualInterpretation ?? false,
          accessibilityWheelchairToilet: data?.accessibilityWheelchairToilet ?? false,
          accessibilityWheelchairVenue: data?.accessibilityWheelchairVenue ?? false,
          accessibilitySignLanguage: data?.accessibilitySignLanguage ?? false,
          environmentStationary: data?.environmentStationary ?? false,
          environmentPrint: data?.environmentPrint ?? false,
          environmentFlyer: data?.environmentFlyer ?? false,
          environmentBattery: data?.environmentBattery ?? false,
          environmentPlastic: data?.environmentPlastic ?? false,
          environmentRecycling: data?.environmentRecycling ?? false,
          environmentDisposable: data?.environmentDisposable ?? false,
          environmentSourceSorting: data?.environmentSourceSorting ?? false,
          environmentFoodEcological: data?.environmentFoodEcological ?? false,
          environmentFoodLocallyProduced: data?.environmentFoodLocallyProduced ?? false,
          environmentFoodEthical: data?.environmentFoodEthical ?? false,
          environmentWater: data?.environmentWater ?? false,
          environmentNoFood: data?.environmentNoFood ?? false,
          environmentFood: data?.environmentFood ?? false,
          environmentServiceQuestion: data?.environmentServiceQuestion ?? false,
          environmentServiceElectricity: data?.environmentServiceElectricity ?? false,
          environmentServiceTravel: data?.environmentServiceTravel ?? false,
          environmentServiceCooking: data?.environmentServiceCooking ?? false,
          inserted: data?.inserted ?? ''
        });
      }
    }
  });

  async function handleSubmit(values: z.infer<typeof baseEventSchema>) {
    if (values.sendToReview) {
      try {
        sendEventSchema.parse(values);
      } catch (err) {
        if (err instanceof z.ZodError) {
          err.errors.forEach(error => {
            // Set errors for each path (field) in the form
            form.setError(error.path[0] as keyof z.infer<typeof sendEventSchema>, {
              type: 'manual',
              message: error.message
            });
          });

          // Trigger form validation again
          return form.trigger();
        }
      }
    }

    if (values.sendToReview) {
      try {
        // const eventId = event?.eventId as number;
        const updateResponse = await updateEvent({ ...event, ...values } as any);
        const newEventId = updateResponse.eventId;
        const sendResponse = await sendEvent(newEventId);

        if (sendResponse.paymentRequired) {
          navigate(`/payment`, { replace: true });
        } else {
          navigate(`/events`);
        }
      } catch (error) {}
    } else {
      try {
        const updateResponse = await updateEvent({ ...event, ...values } as any);
        const newEventId = updateResponse.eventId;

        if (event?.eventId === newEventId) {
          await endSession(Number(id));
        }

        if (event?.eventId !== newEventId) {
          navigate(`/events`, { replace: true });
        }

        setIsLocked(true);

        toast({
          variant: 'success',
          title: label('event_updated_successfully', LabelGroup.ORGANIZER_EVENT_FORM)
        });
      } catch (error) {}
    }
  }

  const handleSession = async () => {
    try {
      if (isLocked) {
        await startSession(Number(id));
        setIsLocked(false);
      } else {
        await endSession(Number(id));
        setIsLocked(true);
      }
    } catch (error) {}
  };

  const comments = useEventStore(state => state.comments);
  const generalComments = comments?.filter(comment => comment.logText.field === 'general');
  const { isEventPeriodClosed } = useTimePeriodRestrictions();
  const filteredSections = sections.filter(({ id }) => (id === 'persons-section' && form.watch('category') === EventCategory.TRAFFPUNKT ? false : true));

  const hasDraftChild = events?.some(e => e.draft?.eventId === event?.eventId);
  const hasEditPermission = form.watch('status') !== EventStatus.Pending && form.watch('status') !== EventStatus.Cancelled && !hasDraftChild;
  const organizationUsers =
    organization?.users?.map(({ user }) => ({
      userId: user.userId,
      name: user.name,
      username: user.username
    })) ?? [];

  const hasEditor = !!event?.draft?.activeEditorId || !!event?.activeEditorId;
  const isEditor = (event?.draft?.activeEditorId || event?.activeEditorId) === user?.userId;
  const activeEditor = organizationUsers.find(user => user.userId === (event?.draft?.activeEditorId ?? event?.activeEditorId));

  // Om nuvarande år inte samma som systemets år och sista dag har passerat och öppningsdatum inte har varit

  useConfirm(
    {
      title: 'there_are_unsaved_changes',
      silent: !form?.formState?.isDirty && isEditor,
      onConfirm: () => isEditor && event?.eventId && endSession(event?.eventId ?? 0)
    },
    form.formState.isDirty || isEditor
  );

  useEffect(() => {
    const handleBeforeUnload = (_: any) => {
      if (isEditor && event?.eventId && !form.formState.isDirty) {
        // Execute your custom function silently
        endSession(event.eventId);
        return undefined;
      }

      if (form.formState.isDirty && !form.formState.isSubmitted && !form.formState.isSubmitting) {
        return 'You have unsaved changes. Are you sure you want to leave?';
      } else {
        return undefined;
      }
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [isEditor, event?.eventId, form.formState.isDirty, form.formState.isSubmitted, form.formState.isSubmitting]);

  if (isFetching || !isFetched || !event || isLoadingEvents) return <EditEventSkeleton />;

  return (
    <>
      {hasEditor && !isEditor && activeEditor && hasActiveEditor && <ActiveEditorAlertDialog editorName={activeEditor?.name !== '' ? activeEditor.name : activeEditor?.username ?? ''} onLockup={() => endSession(event?.eventId ?? 0)} isAdmin={false} />}
      <div ref={containerRef} className="relative w-full overflow-hidden font-form">
        <Form {...form}>
          <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="flex w-full gap-4 ">
              {windowWidth >= 1024 && (
                <div className={cn('w-2/5 md:min-w-[360px]  justify-end hidden lg:flex  max-h-[calc(100lvh_-_121px)]  overflow-y-auto overflow-x-hidden')}>
                  <Sidebar isUpdating={isUpdating} isLocked={isLocked} event={event} />
                </div>
              )}
              <div ref={scrollAreaRef} className="processing-view max-h-[calc(100lvh_-_52px)]  lg:max-h-[calc(100lvh_-_121px)] overflow-y-auto lg:p-4 w-full overflow-x-hidden xl:w-4/5 xl:max-w-[calc(100vw_-_430px)] flex ">
                <div className="space-y-2  w-full max-w-6xl">
                  {windowWidth <= 1024 && <NavigationTabs scrollAreaRef={scrollAreaRef.current} isUpdating={isUpdating} event={event} />}
                  <div className="p-4 space-y-4">
                    <Link to="/" className="block lg:hidden">
                      <Button variant="outline">
                        <ArrowLeft className="h-4 w-4 mr-2" />
                        <Label name="back_to_event_list_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                      </Button>
                    </Link>
                    <StatusStepper status={event?.status} isDraft={!!event?.draftParentId} />
                    <ReturnDue event={event} />
                    <CancelledEventAlert event={event} />
                    <div className={cn('space-y-4 md:space-y-8 pb-6')}>
                      {hasDraftChild && (
                        <div className="bg-brand/10 text-orange-900 text-sm font-semibold flex items-start w-fit p-4 gap-2 rounded-md">
                          <Files className="h-4 w-4 mt-1  stroke-brand" />
                          <div className="flex flex-col">
                            <Label name="event_draft_version_exist" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                            <Link to={`/events/edit/${events.find(e => e.draft?.eventId === event?.eventId)?.eventId}`} className="underline italic">
                              <Label name="edit_draft" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                            </Link>
                          </div>
                        </div>
                      )}
                      <Comment className="bg-sky-50 border-sky-300 text-sky-900" label="general_comment" IconComponent={<Info className="h-4 w-4  mt-1 stroke-sky-500" />} comment={generalComments?.[0]} />

                      {hasEditPermission && event?.yearId === activeYear && (!isEventPeriodClosed || !!event?.draftParentId || event.status === EventStatus.Approved) ? (
                        <>
                          <Button variant="outline" className="bg-brand text-white hover:bg-brand/70 border-0 hover:text-white" type="button" onClick={handleSession}>
                            {!isLocked ? <Lock className="h-4 w-4 mr-2" /> : <UnlockIcon className="h-4 w-4 mr-2" />}
                            <Label name={isLocked ? 'unlock_edit' : 'lock_edit'} groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                          </Button>

                          {!isLocked ? (
                            <>
                              <div className={cn('space-y-4 md:space-y-8 section')}>
                                {filteredSections.map(({ id, component: Component = () => null }) => (
                                  <div id={id}>
                                    <Component form={form} />
                                  </div>
                                ))}
                              </div>
                              {(!isEventPeriodClosed || !!event?.draftParentId || event?.status === EventStatus.Approved) && event?.yearId === activeYear ? (
                                <SendSection isUpdating={isUpdating} isSending={isSending} isFreeOfCharge={!!event?.noCost} />
                              ) : (
                                (event.status === EventStatus.Approved || !!event?.draftParentId) && (
                                  <Button size="sm" disabled={isUpdating || !form.formState.isDirty || isSending} onClick={() => form.setValue('sendToReview', false)} type="submit">
                                    {isUpdating && !form.watch('sendToReview') && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
                                    <Label name="event_save_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                                  </Button>
                                )
                              )}

                              <Separator />
                              <DeviationSettings event={event} />
                            </>
                          ) : (
                            <PreviewSection />
                          )}
                        </>
                      ) : (
                        <>
                          <PreviewSection />
                          <DeviationSettings event={event} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
}
