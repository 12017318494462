import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useOrganization } from '../api/getOrganization';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateOrganization } from '../api/updateOrganization';
import { OrganizationUsers } from '@/features/invitation/components/organization-users';
import { orgNoSchema } from '@/features/misc/data/orgno-schema';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { usePayments } from '@/features/payment/api/get-payments';
import { PayType } from '@/features/payment/types';
import { useOrganizerStore } from '@/stores/organizerStore';
import { Skeleton } from '@/components/ui/skeleton';
import { useMe } from '@/features/auth/api/get-me';
import { UserType } from '@/features/user';
import { Separator } from '@/components/ui/separator';
import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { useYear } from '@/features/settings/api/get-year';
import { NewMainContactTerms } from '../components/new-main-contact-termts';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { formatDate } from '@/utils/formatDate';
import { Checkbox } from '@/components/ui/checkbox';

const formSchema = z.object({
  organizerName: z.string().max(255, {
    message: 'organizer_name_max_length'
  }),
  orgNo: orgNoSchema,
  organizerId: z.number().optional(),
  invoiceOrgNo: orgNoSchema.optional().or(z.literal('')),
  invoiceName: z.string().optional(),
  invoiceAddress1: z.string().optional(),
  invoiceZip: z
    .string()
    .refine(value => /^[0-9]{3}\s?[0-9]{2}$/.test(value), {
      message: 'invalid_zip_code'
    })
    .or(z.literal('')),

  invoiceCity: z.string().optional(),
  invoiceGln: z.string().optional(),
  invoiceAddress2: z.string().optional(),
  invoiceEmail: z.string().email().optional().or(z.literal('')),
  invoiceRef: z.string().optional(),
  signerName: z.string().optional(),
  signerEmail: z.string().email().optional().or(z.literal('')),

  logs: z
    .array(
      z.object({
        organizerId: z.number(),
        userId: z.number().nullable(),
        category: z.string(),
        organizerLogId: z.number(),
        logText: z.string().nullable(),
        inserted: z.string(),
        deleted: z.boolean().default(false).optional()
      })
    )
    .optional(),
  signerTelephone: z
    .string()
    .optional()
    .refine(value => !value || validatePhoneNumber(value), {
      message: 'signer_telephone_invalid'
    })
    .or(z.literal(''))
});

export function EditOrganization() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: user } = useMe({});

  const userId = user?.userId ?? 0;
  const isAdmin = user?.role === UserType.MANAGER || user?.role === UserType.ADMIN;

  const setOrganizerId = useOrganizerStore(state => state.setOrganizationId);

  const { mutateAsync: updateOrganization, isLoading } = useUpdateOrganization({});
  const { data: organization, isLoading: isFetching } = useOrganization({ organizerId: Number(id) });
  const { data: payments = [] } = usePayments({ organizerId: Number(id) });
  const { data: activeYear } = useYear({});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      ...organization,
      organizerName: organization?.organizerName ?? '',
      orgNo: organization?.orgNo ?? '',
      organizerId: organization?.organizerId ?? 0,
      invoiceOrgNo: organization?.invoiceOrgNo ?? '',
      invoiceName: organization?.invoiceName ?? '',
      invoiceAddress1: organization?.invoiceAddress1 ?? '',
      invoiceZip: organization?.invoiceZip ?? '',
      invoiceCity: organization?.invoiceCity ?? '',
      invoiceGln: organization?.invoiceGln ?? '',
      invoiceAddress2: organization?.invoiceAddress2 ?? '',
      invoiceEmail: organization?.invoiceEmail ?? '',
      invoiceRef: organization?.invoiceRef ?? '',
      logs:
        organization?.logs
          ?.filter(log => log.category === 'ConfirmTerms' && log.logText === activeYear?.toString())
          ?.map(
            log =>
              log && {
                organizerId: log.organizerId,
                userId: log.userId,
                category: log.category,
                organizerLogId: log.organizerLogId,
                logText: log.logText,
                inserted: log.inserted,
                deleted: false
              }
          ) ?? [],
      signerName: organization?.signerName ?? '',
      signerEmail: organization?.signerEmail ?? '',
      signerTelephone: organization?.signerTelephone ?? ''
    }
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    const filteredLogs = values.logs?.filter(log => !log.deleted) ?? [];

    await updateOrganization({ ...organization, ...values, logs: filteredLogs } as any);
  }

  const isMainContact = organization?.users?.some(({ mainContact, userId: _userId }) => userId === _userId && mainContact);
  const hasApproval = isMainContact ? organization?.logs.some(log => log.category === 'ConfirmTerms' && log.logText?.toString() === activeYear?.toString() && log.userId === userId) : true;

  if (isFetching || !activeYear) {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-end">
          <Skeleton className="h-8 w-28 rounded-full" />
        </div>
        <Skeleton className="h-40 w-full" />
        <Skeleton className="h-48 w-full" />
      </div>
    );
  }

  if (!hasApproval && organization && isMainContact) {
    return <NewMainContactTerms organizer={organization} />;
  }

  return (
    <div className="space-y-6 flex flex-col w-full">
      <div className="space-y-6">
        <Form {...form}>
          <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
            <div className="flex justify-end items-center gap-2">
              {!isAdmin && (
                <Button
                  size="sm"
                  type="button"
                  variant="outline"
                  onClick={() => {
                    if (organization?.organizerId) {
                      setOrganizerId(organization.organizerId);
                      navigate(`/events/create`);
                    }
                  }}>
                  <Label name="create_event_button_text" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                </Button>
              )}
              {(isMainContact || isAdmin) && (
                <Button size="sm" disabled={isLoading || form.formState.isSubmitting || !form.formState.isDirty} type="submit">
                  <Label name={isLoading ? 'updating' : 'update'} groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                </Button>
              )}
            </div>

            <Card>
              <CardHeader>
                <CardTitle>
                  <Label name="organization_details" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="grid md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="organizerName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} maxLength={255} disabled={!isMainContact && !isAdmin} readOnly={!isMainContact && !isAdmin} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="orgNo"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="org_no" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} disabled={!isMainContact && !isAdmin} readOnly={!isMainContact && !isAdmin} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <Separator className="my-2" />
                <div className="grid md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="signerName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="signer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isAdmin} {...field} maxLength={500} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="signerEmail"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="signer_email" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="signerTelephone"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="signer:telephone" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isAdmin} {...field} maxLength={32} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
            {payments.some(p => p.payment.payType === PayType.Invoice) && (
              <Card>
                <CardHeader>
                  <CardTitle>
                    <Label name="invoice_details" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                  </CardTitle>
                </CardHeader>
                <CardContent className="grid md:grid-cols-2 gap-8">
                  <FormField
                    control={form.control}
                    name="invoiceOrgNo"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_org_no" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                          <span className="text-red-500 ml-2">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                          <span className="text-red-500 ml-2">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceAddress1"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_address1" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                          <span className="text-red-500 ml-2">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceAddress2"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_address2" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceZip"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_zip" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                          <span className="text-red-500 ml-2">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceCity"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_city" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                          <span className="text-red-500 ml-2">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceGln"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_gln" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceEmail"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_email" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} type="email" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="invoiceRef"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>
                          <Label name="organizer_invoice_ref" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                        </FormLabel>
                        <FormControl>
                          <Input disabled={!isMainContact && !isAdmin} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </CardContent>
              </Card>
            )}

            {isAdmin && (
              <Card>
                <CardHeader>
                  <CardTitle>
                    <Label name="agreement_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                  </CardTitle>
                  <CardDescription>
                    <Label name="agreement_description" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>
                          <Label name="confirmed_by" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                        </TableHead>
                        <TableHead>
                          <Label name="date" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                        </TableHead>
                        <TableHead>
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {form.watch('logs')?.map((log, index) => (
                        <TableRow key={log.organizerLogId}>
                          <TableCell className="font-medium">{organization?.users.find(({ userId }) => userId === log.userId)?.user?.username}</TableCell>
                          <TableCell>{formatDate(log.inserted)}</TableCell>
                          <TableCell className="text-center">
                            <FormField
                              control={form.control}
                              name={`logs.${index}.deleted`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormControl>
                                    <Checkbox checked={!field.value} onCheckedChange={checked => field.onChange(!checked)} />
                                  </FormControl>
                                </FormItem>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            )}
            {(isMainContact || isAdmin) && (
              <Button size="sm" className="block md:hidden" disabled={isLoading || form.formState.isSubmitting || !form.formState.isDirty} type="submit">
                <Label name={isLoading ? 'updating' : 'update'} groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
              </Button>
            )}
          </form>
        </Form>
        {organization && <OrganizationUsers organization={organization} />}
      </div>
    </div>
  );
}
