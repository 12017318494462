import { DataTable } from '@/components/ui/data-table';

import { DataTableSkeleton } from '@/components/data-table-skeleton';

import { Row } from '@tanstack/react-table';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { organizerColumns } from '../components/(organizer)/organizer-columns';
import { useAllOrganizations } from '@/features/organizer/api/get-all-organizations';
import { Organization } from '@/features/organizer/types';
import { useYear } from '@/features/settings/api/get-year';
import { label } from '@/utils/label';

export function Organizers() {
  const { data: organizers = [], isLoading } = useAllOrganizations({});
  const { data: activeYear } = useYear({});

  const formattedData: (Partial<Organization> & {
    mainContact: string;
    acceptedTerms: boolean;
  })[] = organizers.map(org => ({
    organizerId: org?.organizerId,
    organizerName: org?.organizerName,
    orgNo: org?.orgNo,
    invoiceOrgNo: org?.invoiceOrgNo,
    invoiceGln: org?.invoiceGln,
    invoiceName: org?.invoiceName,
    invoiceAddress1: org?.invoiceAddress1,
    invoiceAddress2: org?.invoiceAddress2,
    invoiceZip: org?.invoiceZip,
    invoiceCity: org?.invoiceCity,
    invoiceTelephone: org?.invoiceTelephone,
    invoiceEmail: org?.invoiceEmail,
    invoiceRef: org?.invoiceRef,
    signerName: org?.signerName,
    signerEmail: org?.signerEmail,
    signerTelephone: org?.signerTelephone,
    mainContact: org?.users?.find(user => !!user.mainContact)?.user?.username,
    acceptedTerms: org?.logs?.some(log => log.category === 'ConfirmTerms' && log.logText === activeYear?.toString())
  })).sort((a, b) => a.organizerName.localeCompare(b.organizerName));

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-1 items-center">
            <h3 className="text-xl font-semibold">
              <Label name="organizers_page_title" groupName={LabelGroup.ADMIN_ORGANIZATION_LIST} />
            </h3>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {!isLoading ? (
          <>
            <DataTable
              columns={organizerColumns}
              data={formattedData}
              config={{
                maxHeight: 'calc(100lvh - 250px)',
                getLabel: (text: string) => label(text, LabelGroup.ADMIN_ORGANIZATION_LIST),
                getSearchFields: row => [
                  (row as Row<Organization>).original.organizerName,
                  (row as Row<Organization>).original?.orgNo ?? '',
                  (row as Row<Organization>).original?.invoiceOrgNo ?? '',
                  (
                    row as Row<
                      Organization & {
                        mainContact: string;
                      }
                    >
                  ).original.mainContact
                ]
              }}
            />
          </>
        ) : (
          <DataTableSkeleton />
        )}
      </div>
    </div>
  );
}
