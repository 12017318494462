import { Fragment } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';
import { User } from '../types';
import { UserTableRowActions } from './user-table-row-actions';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';

export const organizerColumns: ColumnDef<User>[] = [
  {
    accessorKey: 'userId',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="user_id" groupName={LabelGroup.ADMIN_USER_LIST} />} />,
    cell: ({ row }) => <span className="font-bold">{row.original.userId}</span>
  },
  {
    accessorKey: 'username',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="username" groupName={LabelGroup.ADMIN_USER_LIST} />} />
  },
  {
    accessorKey: 'name',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="name" groupName={LabelGroup.ADMIN_USER_LIST} />} />,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'telephone',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="telephone" groupName={LabelGroup.ADMIN_USER_LIST} />} />,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'organizerUsers',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="user_organization_count" groupName={LabelGroup.ADMIN_USER_LIST} />} />,
    cell: ({ row }) => {
      const qnty = row.original.organizerUsers?.length ?? 0;

      return (
        <div className="w-96">
          {[...(row.original.organizerUsers ?? [])]?.map(({ organizer }: any, index: number) => (
            <Fragment key={organizer.organizerId}>
              <Link to={`/organizations/${organizer.organizerId}`} className="underline hover:text-brand">
                <span>{organizer.organizerName}</span>
              </Link>
              {index + 1 < qnty && qnty > 1 && ', '}
            </Fragment>
          ))}
        </div>
      );
    }
  },
  {
    accessorKey: 'lastLogin',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="last_login" groupName={LabelGroup.ADMIN_USER_LIST} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    cell: ({ row }) => {
      return row.original.lastLogin && convertUTCtoLocal(row.original.lastLogin);
    }
  },
  {
    accessorKey: 'locked',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="locked" groupName={LabelGroup.ADMIN_USER_LIST} />}
      />
    ),
    cell: ({ row }) => {
      return row.original.locked && <Check className="h-4 w-4 text-green-500" />
    },
  },
  {
    accessorKey: 'failedLoginAttempts',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="failed_login_attempts" groupName={LabelGroup.ADMIN_USER_LIST} />}
      />
    ),
    cell: ({ row }) => {
      return row.original.failedLoginAttempts 
    },
  },
  {
    accessorKey: 'action',
    header: '',
    enableHiding: false,
    cell: ({ row }) => <UserTableRowActions row={row} />
  }
];
